import React from "react";
import "./Sidebar.css";
// Material UI Icons
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import LibraryMusicIcon from "@material-ui/icons/LibraryMusic";
// Components
import SidebarOption from "./SidebarOption";
import { useDataLayerValue } from "./DataLayer";

function Sidebar() {
  const [{ playlists }] = useDataLayerValue();

  return (
    <div className="sidebar">
      <img
        className="sidebar__logo"
        src="https://getheavy.com/wp-content/uploads/2019/12/spotify2019-830x350.jpg"
        alt=""
      />

      <SidebarOption key="Home" Icon={HomeIcon} title="Home" />
      <SidebarOption key="Search" Icon={SearchIcon} title="Search" />
      <SidebarOption
        key="Your Library"
        Icon={LibraryMusicIcon}
        title="Your Library"
      />

      <br />
      <strong className="sidebar_title">PLAYLISTS</strong>
      <hr />

      {playlists?.items?.map((playlist) => (
        <SidebarOption key={playlist.name} title={playlist.name} />
      ))}

      {/* <SidebarOption title="HipHop" />
      <SidebarOption title="Rap" />
      <SidebarOption title="RnB" /> */}
    </div>
  );
}

export default Sidebar;
