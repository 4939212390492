import React from "react";
import "./Body.css";
// CONTEXT API DATA LAYER
import { useDataLayerValue } from "./DataLayer";
// icons
import { Favorite, MoreHoriz, PlayCircleFilled } from "@material-ui/icons";

// Components
import Header from "./Header";
import SongRow from "./SongRow";

function Body({ spotify }) {
  const [{ discover_weekly }] = useDataLayerValue();

  return (
    <div className="body">
      <Header />

      <div className="body__info">
        <img src={discover_weekly?.images[0].url} alt="" />
        <div className="body__infoText">
          <strong>PLAYLIST: {discover_weekly?.owner?.display_name}</strong>
          <h2>{discover_weekly?.name}</h2>
          <p>{discover_weekly?.description}</p>
        </div>
      </div>

      <div className="body__songs">
        {/* Body Icons - Song Icons */}
        <div className="body__icons">
          <PlayCircleFilled className="body__shuffle" />
          <Favorite fontSize="large" />
          <MoreHoriz />
        </div>
        {/* List of Songs */}
        {discover_weekly?.tracks.items.map((item) => (
          <SongRow key={item.track.name} track={item.track} />
        ))}
      </div>
    </div>
  );
}

export default Body;
