import React, { useEffect } from "react";
import "./App.css";
// Spotify Web API SETUP
import SpotifyWebApi from "spotify-web-api-js";
import { getTokenFromUrl } from "./spotify";
// Components
import Login from "./Login";
import Player from "./Player";
// DataLayer
import { useDataLayerValue } from "./DataLayer";

//Instance of Spotify web api
const spotify = new SpotifyWebApi();

function App() {
  ////const [token, setToken] = useState(null);
  // Pick data from datalayer:
  /// value in curly brace is the varaible accessible or passed to the component from the datalayer,
  /// dispatch is used to write to the datalayer
  const [{ token }, dispatch] = useDataLayerValue();

  // Run code based on a given condition
  useEffect(() => {
    const hash = getTokenFromUrl();
    window.location.hash = "";
    const _token = hash.access_token;

    if (_token) {
      dispatch({
        type: "SET_TOKEN",
        token: _token,
      });
      ////setToken(_token);

      spotify.setAccessToken(_token);

      // Add user gotten to dataLayer
      spotify.getMe().then((user) => {
        dispatch({
          type: "SET_USER",
          user: user,
        });
      });

      // get spotify playlist
      spotify.getUserPlaylists().then((playlists) => {
        dispatch({
          type: "SET_PLAYLISTS",
          playlists: playlists,
        });
      });

      spotify.getPlaylist("37i9dQZEVXcOcwo827DgiQ").then((response) => {
        dispatch({
          type: "SET_DISCOVER_WEEKLY",
          discover_weekly: response,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Use Effect runs when the app loads and when the values of parameters in the square bracket changes

  return (
    <div className="app">
      {token ? <Player spotify={spotify} /> : <Login />}
      {/* <Login /> */}
    </div>
  );
}

export default App;
