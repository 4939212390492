import React, { createContext, useContext, useReducer } from "react";

export const DataLayerContext = createContext();

export const DataLayer = ({ initialState, reducer, children }) => (
  // arrangement of parameters passed in useReducer is important
  <DataLayerContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </DataLayerContext.Provider>
);

// function to get access to a value or when dispatch-ing an action to a value
export const useDataLayerValue = () => useContext(DataLayerContext);
